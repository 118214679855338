@font-face {
  font-family: 'MonumentalExtended';
  src: url('./fonts/MonumentExtended-Ultrabold.otf') format('opentype'); /* IE9 Compat Modes */
  font-weight: 700;
}

@font-face {
  font-family: 'MonumentalExtended';
  src: url('./fonts/MonumentExtended-Regular.otf') format('opentype'); /* IE9 Compat Modes */
  font-weight: 500;
}

@font-face {
  font-family: 'Chaney-Regular';
  src: url('./fonts/chaney/chaney-regular-webfont.woff2') format('woff2'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Chaney-Wide';
  src: url('./fonts/chaney/chaney-wide-webfont.woff2') format('woff2'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'MaisonNeue-Book';
  src: url('./fonts/Maison\ Neue\ -\ Book.ttf') format('truetype'); /* IE9 Compat Modes */
  font-weight: 100;
}

@font-face {
  font-family: 'MaisonNeue';
  src: url('./fonts/Maison\ Neue\ -\ Mono.ttf') format('truetype'); /* IE9 Compat Modes */
  font-weight: 100;
}

@font-face {
  font-family: 'MaisonNeue';
  src: url('./fonts/Maison\ Neue\ -\ Bold.ttf') format('truetype'); /* IE9 Compat Modes */
  font-weight: 600;
}

@font-face {
  font-family: 'MaisonNeue';
  src: url('./fonts/Maison\ Neue\ -\ Book.ttf') format('truetype'); /* IE9 Compat Modes */
  font-weight: 300;
}

* {
  box-sizing: border-box;
  text-decoration: none;
}

html, body, .App {
  width: 100vw;
  height: 100vh;
  background-color: #F7F6F4;
}

html, body, .App, #root { 
  overscroll-behavior: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow: hidden;
}

a {
  text-decoration: none;
  color: black;
}

a:hover, a:focus {
  text-decoration: none;
  color: black;
}

h1 {
  font-family: 'Chaney-Regular', Fallback, sans-serif;
  font-size: 26px;
  font-weight: 100;
  text-transform: uppercase;
  margin: 0;
}

h2 {
  font-family: 'Chaney-Wide', Fallback, sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  margin: 0;
  font-weight: 100;
}
/* 
h2 {
  font-family: 'MonumentalExtended', Fallback, sans-serif;
  font-size: 22px;
  text-transform: uppercase;
  margin: 0;
} */

h3 {
  font-family: 'MaisonNeue', Fallback, sans-serif;
  font-weight: 100;
  font-size: 16px;
}

h4 {
  font-family: 'MaisonNeue', Fallback, sans-serif;
  font-weight: 600;
}

p {
  font-family: 'MaisonNeue', Fallback, sans-serif;
  font-size: 15px;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media(max-width: 1024px) {
  html, body, .App {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    overflow: hidden;
  }
}
